import { FunctionComponent, useEffect, useState } from "react";

import HomePageVertical from "./verticalView";
import HomePageHorizontal from "./horizontalView";
interface HomePageProps {
  orientation?:number|string;
}

const HomePage: FunctionComponent<HomePageProps> = ({orientation}) => {
 

  return (
    <HomePageVertical />
    // <>{orientation !== 0 ? <HomePageHorizontal /> : <HomePageVertical />}</>
  );
  // return <HomePageHorizontal />;
};

export default HomePage;
