import { useState } from "react";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  
} from "react-router-dom";
import Layout from "../components/layout";
import Home from "../page/home";
import React from "react";
import Loading from "../components/loading";
import { ThemeProvider } from "@material-tailwind/react";
import Kitchen from "../page/kitchen";
import BedroomPage from "../page/bedroom";
import LivingRoom from "../page/livingRooom";
import Closet from "../page/closet";
import Bathroom from "../page/bathroom";
import AboutUs from "../page/aboutUs";
import ContactUs from "../page/contactUs";
import "swiper/css";
import "swiper/css/navigation";
const Routes = () => {
  const [loading, setLoading] = useState(true);
  const location = window.location.pathname;
  console.log(location);
  const loadingTime = location === "/" ? 0: 3;
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Layout>
          <Outlet />
        </Layout>
      ),
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/kitchen",
          element: <Kitchen />,
        },
        {
          path: "/bedroom",
          element: <BedroomPage />,
        },
        {
          path: "/livingroom",
          element: <LivingRoom />,
        },
        {
          path: "/closet",
          element: <Closet />,
        },
        {
          path: "/bathroom",
          element: <Bathroom />,
        },
        {
          path: "/aboutus",
          element: <AboutUs />,
        },
        {
          path: "/contactus",
          element: <ContactUs />,
        },
      ],

      errorElement: <>Erorr</>,
      loader: async () => {
        setTimeout(() => {
          setLoading(false);
        }, loadingTime * 1000);
        return true;
      },
    },
  ]);
  if (loading) return <Loading loadingTime={loadingTime} />;
  return (
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default Routes;
