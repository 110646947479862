import * as THREE from "three";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import {
  Html,
  Environment,
  useGLTF,
  ContactShadows,
  OrbitControls,
  useProgress,
} from "@react-three/drei";
import kitchenImage from "../../assets/Component 23.png";
import bedroomImage from "../../assets/Component 8.png";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { randInt } from "three/src/math/MathUtils";
import Loading from "../../components/loading";
import * as animationData from "../../assets/cabinet_v2_black.json";
import * as animationData1 from "../../assets/cabinet_v2_white.json";
import Lottie from "react-lottie";

// import { useFrame } from '@react-three/fiber';

const Modelsmall = () => {
  const group = useRef();
  const group1 = useRef();
  const group2 = useRef();
  const group3 = useRef();
  const group4 = useRef();
  const group5 = useRef();

  const ref = useRef();

  const gltf = useLoader(GLTFLoader, "/3d/House.gltf");

  const gltf1 = useLoader(GLTFLoader, "/3d/Closet.gltf");
  const gltf2 = useLoader(GLTFLoader, "/3d/Bedroom.gltf");
  const gltf3 = useLoader(GLTFLoader, "/3d/Bathroom.gltf");
  const gltf4 = useLoader(GLTFLoader, "/3d/kitchen.gltf");
  const gltf5 = useLoader(GLTFLoader, "/3d/LivingRoom.gltf");

  const [showElements, setShowElements] = useState(false);

  const [isOccluded, setOccluded] = useState();
  const [isInRange, setInRange] = useState();
  const isVisible = isInRange && !isOccluded;
  const vec = new THREE.Vector3();
  // useFrame((state) => {
  //   const range =
  //     state.camera.position.distanceTo(group5.current.getWorldPosition(vec)) <=
  //     34;

  //   // console.log(
  //   //   state.camera.position.distanceTo(group5.current.getWorldPosition(vec))
  //   // );
  //   if (range !== isInRange) setInRange(range);
  // });

  // useEffect(() => {
  //   console.log("isVisible", isVisible, isOccluded, isInRange);
  // }, [isVisible, isOccluded, isInRange]);
  // Inside your component...
  // useFrame(({ camera }) => {
  //   // const rotationAngle = clock.getElapsedTime();
  //   const rotationAngle = camera.position.x;
  //   const thresholdAngle = Math.PI ;
  //   console.log("neshun bedee",rotationAngle,thresholdAngle)
  //   // if (rotationAngle >= thresholdAngle) {
  //   //   setShowElements(true);
  //   //   console.log("neshun bedee",rotationAngle,thresholdAngle)
  //   //   // Show the elements when the condition is met
  //   // } else {
  //   //   setShowElements(false);
  //   //   console.log("neshun nabedee")
  //   //    // Hide the elements when the condition is not met
  //   // }
  // });
  // useFrame((state) => {
  //   const t = state.clock.getElapsedTime();
  //   group.current.rotation.x = THREE.MathUtils.lerp(
  //     group.current.rotation.x,
  //     Math.cos(t / 2) / 20 + 0.25,
  //     0.1
  //   );
  //   group.current.rotation.y = THREE.MathUtils.lerp(
  //     group.current.rotation.y,
  //     Math.sin(t / 1) / 20,
  //     0.1
  //   );
  //   group.current.rotation.z = THREE.MathUtils.lerp(
  //     group.current.rotation.z,
  //     Math.sin(t / 8) / 30,
  //     0.5
  //   );
  //   group.current.position.y = THREE.MathUtils.lerp(
  //     group.current.position.y,
  //     (-2 + Math.sin(t / 2)) / 2,
  //     0.1
  //   );
  // });
  return (
    <>
      <group dispose={null} ref={group} scale={[1, 1, 1]}>
        <primitive object={gltf.scene}>
          <mesh
            geometry={
              gltf.nodes["Plant_Basil_A_spring-summer-autumn.019"]?.geometry
            }
            material={"Stem_Green_bqm"}
          >
            <Marker
              name="kitchen"
              position={[Math.PI + 12, Math.PI, Math.PI - 9]}
              Modle={gltf4.scene}
              right={true}
            ></Marker>
            <Marker
              name="closet"
              position={[Math.PI - 15, Math.PI, Math.PI - 9.9]}
              Modle={gltf1.scene}
              right={false}
            ></Marker>
              <Marker
              name="bathroom"
              position={[Math.PI - 14.7, Math.PI - 3.1, Math.PI + 6.0]}
              Modle={gltf3.scene}
              right={false}
            ></Marker>


<Marker
              name="livingroom"
              position={[Math.PI + 9.2, Math.PI - 3.0, Math.PI + 5.6]}
              Modle={gltf5.scene}
              right={true}
            ></Marker>

<Marker
              name="bedroom"
              position={[Math.PI + 9.2, Math.PI + 2, Math.PI + 6.4]}
              Modle={gltf2.scene}
              right={true}
            ></Marker>

            <Html
              className="absolute content"
              center
              position={[Math.PI + 12, Math.PI - 4, Math.PI - 9]}
              // sprite
              transform
              // onOcclude={setOccluded}
              rotation-y={-Math.PI}
            >
              {/* <div className="absolute top-0 w-[500px] left-[280px]"> */}
              <a href="/kitchen">
                {/* <img src={bedroomImage} alt="" className="w-full" /> */}
                <span className="text-4xl  text-mainColor px-[140px] py-[20px]">
                  {/* kitchen. */}
                </span>
              </a>
              {/* </div> */}
            </Html>

            <Html
              className="relative content"
              rotation-y={-Math.PI}
              center
              position={[Math.PI - 15, Math.PI + 2, Math.PI - 9.9]}
              // sprite
              transform
            >
              {/* <div className="absolute top-0 w-[500px] left-[280px]"> */}
              <a href="/closet">
                {/* <img src={bedroomImage} alt="" className="w-full" /> */}
                <span className="text-4xl   text-mainColor px-[140px] py-[20px]">
                  {/* closet. */}
                </span>
              </a>
              {/* </div> */}
            </Html>
            <Html
              className="relative content"
              // rotation-x={-Math.PI / 2}
              position={[Math.PI - 14.7, Math.PI - 5.1, Math.PI + 6.0]}
              transform
              center

              // sprite
            >
              <div
                className="relative wrapper"
                onPointerDown={(e) => e.stopPropagation()}
              >
                {/* <div className="absolute top-[230px] left-[1300px]"> */}
                <a href="/bathroom">
                  <span className="text-4xl    text-mainColor px-[130px] py-[20px]">
                    {/* bathroom. */}
                  </span>
                </a>
              </div>
              {/* </div> */}
            </Html>
            <Html
              className="relative content"
              // rotation-x={-Math.PI / 2}
              position={[Math.PI + 9.2, Math.PI - 5.0, Math.PI + 5.6]}
              transform
              center

              // sprite
            >
              <>
                <div
                  className="relative wrapper"
                  onPointerDown={(e) => e.stopPropagation()}
                >
                  {/* <div className="absolute -top-[100px] w-[600px] left-[1090px]"> */}
                  <a href="/livingroom">
                    {/* <img src={bedroomImage} alt="" className="w-full" /> */}
                    <span className="text-4xl  text-mainColor px-[140px] py-[15px]">
                      {/* <h1 className="text-lg bg-red-400">
                        dsfsdfsdfsdfsdfsdfsdf
                      </h1> */}
                      {/* livingRoom. */}
                    </span>
                  </a>
                </div>
              </>
            </Html>
            <Html
              className="relative content"
              // rotation-x={-Math.PI / 2}
              position={[Math.PI + 9.2, Math.PI + 0, Math.PI + 6.4]}
              // sprite

              transform
              center
            >
              <div
                className="relative wrapper"
                onPointerDown={(e) => e.stopPropagation()}
              >
                {/* <div className="absolute -top-[100px] w-[600px] left-[1090px]"> */}
                <a href="/bedroom">
                  {/* <img src={bedroomImage} alt="" className="w-full" /> */}
                  <span className="text-4xl   text-mainColor px-[140px] py-[15px] ">
                    {/* bedroom. */}
                  </span>
                </a>
                {/* </div> */}
              </div>
            </Html>
          </mesh>
        </primitive>
      </group>
    </>
  );
};

function Marker(props) {
  const ref = useRef();
  const ref2 = useRef();
  // This holds the local occluded state
  const [isOccluded, setOccluded] = useState();
  const [isInRange, setInRange] = useState();
  const isVisible = isInRange && !isOccluded;
  const gltf2 = useLoader(GLTFLoader, "/3d/LivingRoom.gltf");
  // Test distance
  const vec = new THREE.Vector3();
  useFrame((state) => {
    const range =
      state.camera.position.distanceTo(ref.current.getWorldPosition(vec)) <= 34;
    // console.log( state.camera.position.distanceTo(ref.current.getWorldPosition(vec)));
    if (range !== isInRange) setInRange(range);
    if (isVisible) {
      if (props.right === true && ref2.current.position.x <= 0) {
        ref2.current.position.x += 0.05;
      } else if (props.right === false && ref2.current.position.x >= 0) {
        ref2.current.position.x -= 0.05;
      }
    }
    else {
      if (props.right === true && ref2.current.position.x >= -10) {
        ref2.current.position.x -= 0.2;
      } else if (props.right === false && ref2.current.position.x <= 10) {
        ref2.current.position.x += 0.2;
      }
    }
  });

  useEffect(() => {
    console.log("isVisible===", isVisible, isOccluded, isInRange);

    // else{
    //   ref2.current.rotation.y -= 0.01;
    // }
  }, [isVisible, isOccluded, isInRange]);

  return (
    <>
      <group ref={ref} dispose={null} scale={[1, 1, 1]}>
        {true && (
          <primitive
            object={props.Modle}
            ref={ref2}
            position={[props.right ? -10 : 10, 0, 0]}
          >
            <mesh
            // geometry={
            //   gltf2.nodes["Plant_Basil_A_spring-summer-autumn.019"]
            //     ?.geometry
            // }
            // material={"Stem_Green_bqm"}
            ></mesh>
          </primitive>
        )}
        <Html
          className="relative content"
          // rotation-x={-Math.PI / 2}
          position={props.position}
          transform
          center
          occlude
          onOcclude={setOccluded}
          style={{
            transition: isVisible ? "all 1s" : "all 0s",
            opacity: isVisible ? 1 : 0,
            transform: `scale(${isVisible ? 1 : 0.25})`,
          }}
          // sprite
        >
          <>
            {/* <h1 className="text-lg bg-red-400">dsfsdfsdfsdfsdfsdfsdf</h1> */}
          </>
        </Html>
      </group>
    </>
  );
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  speed: 2,
  // rendererSettings: {
  //   preserveAspectRatio: 'xMidYMid slice'
  // }
};
const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: animationData1,
  speed: 2,

  // rendererSettings: {
  //   preserveAspectRatio: 'xMidYMid slice'
  // }
};

function Loader() {
  const { progress } = useProgress();

  return (
    <Html
      center
      className="flex flex-col text-5xl text-center text-black align-middle loading dark:text-white "
    >
      {/* <div> {parseInt(progress)} % </div> */}
      <div className="w-80">
        <div className="hidden dark:block">
          <Lottie options={defaultOptions1} />
        </div>
        <div className="block dark:hidden">
          <Lottie options={defaultOptions} />
        </div>
        <div className="mt-5 text-3xl font-Inria">Building ....</div>
      </div>
    </Html>
  );
}

export default function Render3D(props) {
  // const [zoom, setZoom] = useState([-15, 16, -30]);
  const [zoom, setZoom] = useState([-15, 0, -30]);

  // useEffect(() => {
  //   setInterval(() => {
  //     setZoom([zoom[0] + 5, zoom[1] + 5]);
  //   }, 1000);
  // }, []);
  return (
    // flex items-end justify-center
    <>
      <section
        className={`h-full lg:w-full lg:px-10 lg:mx-5 lg:h-3/4 hidden lg:block`}
      >
        <Canvas camera={{ position: zoom, fov: 75}}>
          <Suspense fallback={<Loader />}>
            {/* <pointLight position={[10, 10, 10]} intensity={0.5} /> */}
            <ambientLight intensity={0.5} />
            {/* <group rotation={[0, Math.PI / 7, 0]} position={[0, 0, 0]}> */}
            <Modelsmall className="hidden lg:block" />
            {/* <Modelsmall  className="block lg:hidden"/> */}
            {/* </group> */}
            <Environment preset="city" />
            {/* <ContactShadows
          position={[-10, 0, -30]}
          scale={50}
          blur={2}
          far={4.5}
        /> */}
            <OrbitControls
              // makeDefault
              // enablePan={true}
              // position={[-10, Math.PI / 2.2, -30]}
              enableZoom={false}
              minPolarAngle={Math.PI / 2.5}
              maxPolarAngle={Math.PI / 2.1}
              // enableDamping={true}
              // enableRotate={true}
              autoRotate
              autoRotateSpeed={3}
            />
          </Suspense>
        </Canvas>
      </section>
      <section
        className={`h-full lg:w-full lg:px-10 lg:mx-5 lg:h-3/4 block lg:hidden`}
      >
        <Canvas camera={{ position: zoom, fov: 75 }} className="!w-screen">
          <pointLight position={[10, 10, 10]} intensity={5} />
          <Suspense fallback={<Loader />}>
            {props.orientation !== 0 ? (
              <group
                rotation={[0, Math.PI / 7, 0]}
                position={[0, 0, 0]}
                // scale={[1.2, 1.2, 1.2]}
                scale={[1, 1, 1]}
                castShadow={true}
                isObject3D={true}
                isGroup={true}
              >
                {/* <Model  className="hidden lg:block"/> */}
                <Modelsmall className="block lg:hidden" />
              </group>
            ) : (
              <group
                rotation={[0, Math.PI / 270, 0]}
                position={[0, 0, 0]}
                scale={[0.8, 0.8, 0.8]}
                castShadow={true}
                isObject3D={true}
                isGroup={true}
              >
                {/* <Model  className="hidden lg:block"/> */}
                <Modelsmall className="block lg:hidden" />
              </group>
            )}

            <Environment preset="city" />
          </Suspense>
          {/* <ContactShadows
         position={[-10, 0, -30]}
         scale={50}
         blur={2}
         far={4.5}
       /> */}
          <OrbitControls
            enablePan={true}
            position={[-10, Math.PI / 2.2, -30]}
            enableZoom={false}
            minPolarAngle={Math.PI / 2.5}
            maxPolarAngle={Math.PI / 2.1}
            enableDamping={true}
            enableRotate={true}
            autoRotate
            autoRotateSpeed={3}
          />
        </Canvas>
      </section>
    </>
  );
}
