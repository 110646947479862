
import { forwardRef } from "react";
import { IconSvgProps } from "./IconSvgProps";

const Instagramlight = forwardRef<SVGSVGElement, IconSvgProps>((props, ref) => {
    const { className, size = 24, color = "currentColor", strokeWidth = 1 } = props;

    return (
       
        <svg width="30" className={className} height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        
        <g>
            <path className="st0"  stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round"  strokeWidth="1.3" d="M19.17,6.67h-8.33c-2.3,0-4.17,1.87-4.17,4.17v8.33c0,2.3,1.87,4.17,4.17,4.17h8.33c2.3,0,4.17-1.87,4.17-4.17
                v-8.33C23.33,8.53,21.47,6.67,19.17,6.67z"/>
            <path className="st0"  stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3"d="M18.33,14.47c0.1,0.69-0.02,1.4-0.34,2.02c-0.32,0.62-0.83,1.13-1.46,1.44c-0.63,0.32-1.34,0.42-2.03,0.31
                c-0.69-0.11-1.33-0.44-1.83-0.93c-0.5-0.5-0.82-1.14-0.93-1.83c-0.11-0.69,0-1.4,0.31-2.03c0.32-0.63,0.82-1.14,1.44-1.46
                c0.62-0.32,1.33-0.44,2.02-0.34c0.71,0.1,1.36,0.43,1.87,0.94C17.9,13.11,18.23,13.77,18.33,14.47z"/>
            <path className="st1"   stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19.58,10.42L19.58,10.42"/>
        </g>
        </svg>
        
        
    );
});

Instagramlight.displayName = "InstagramLight";

export default Instagramlight;



