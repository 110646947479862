import { FunctionComponent } from "react";
import ArrowRight from "../../assets/icon/arrowRight";
import Fade from "react-reveal/Fade";

interface MoreDetailsPageProps {
  title: string;
  content: string;
    contents: string;
  image: any;
  handleClick: any;
}

const MoreDetailsPage: FunctionComponent<MoreDetailsPageProps> = ({
  title,
  content,
  contents,
  image,
  handleClick,
}) => {
  // console.log(image);
  return (
    <section
          className="w-screen h-screen bg-no-repeat bg-cover "
      // style={{ backgroundImage: `url${image}` }}
    >
      <Fade top big duration={2000}>
              <div className="absolute inset-0 overflow-hidden bg-black z-5 lg:z-auto absolute  h-full">
                  <img src={image} alt="" className=" z-50 mt-20 lg:mt-0 lg:absolute lg:h-full  lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2 lg:w-full lg:max-w-none lg:top-1/2 lg:left-1/2"/>
        </div>
      </Fade>
      <Fade left big duration={2000}>
        <div className="absolute lg:left-0  left-3 right-3 lg:right-[20%] z-20 bg-black bottom-[10%] top-[42%] lg:top-1/4 bg-opacity-60 lg:px-[132px]">
          <h3 className="-mt-6 text-5xl text-white mb-9 font-Inria">{title}</h3>
                  <p className="mb-5 text-base text-white font-inter 	text-justify	">{contents}</p>
          <button
            onClick={() => handleClick()}
            className="relative  text-mainColor"
          >
            Back
          </button>
        </div>
      </Fade>
      <Fade bottom big duration={2000} >
        <div className="lg:absolute z-10 lg:z-30 lg:py-20 top-[0]  h-20 mx-auto  lg:right-[10%] lg:h-full w-[200px] bg-mainColor after:bg-mainColor  lg:flex flex-col capitalize text-5xl justify-around  items-center text-white font-Inria">
          <Fade top big duration={2000}>
           <div className="hidden lg:contents">
           {title.split("").map((item) => {
              return <span>{item}</span>;
            })}
           </div>
          </Fade>
        </div>
      </Fade>
    </section>
  );
};

export default MoreDetailsPage;
