export default function sliceArrayIntoGroupsOfFour(bigArray:any[]) {
    const result = [];
    const groupSize = 4;
  
    for (let i = 0; i < bigArray.length; i += groupSize) {
      const smallArray = bigArray.slice(i, i + groupSize);
      result.push(smallArray);
    }
  
    return result;
  }