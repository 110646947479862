import { FunctionComponent, useEffect, useState } from "react";
import logo from "../../assets/logo/logo.svg";
import logoLight from "../../assets/logo/logolight.svg";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Drawer, Switch } from "@material-tailwind/react";
import CustomSwitch from "../customSwitch";
import { useLocation } from "react-router-dom";
import { Sling as Hamburger } from "hamburger-react";
// import { Divide as Hamburger } from 'hamburger-react'
interface HeaderMainProps {
  onChange: any;
  orientation?: number | string;
  darkToggle?: boolean;
}

const HeaderMain: FunctionComponent<HeaderMainProps> = ({
  onChange,
  darkToggle,
}) => {
  const [open, setOpen] = useState(false);
  const openDrawer = () => {
    console.log("open", open);
    setOpen(true);
  };
  const closeDrawer = () => {
    console.log("close", open);
    setOpen(false);
  };
  const toggleDrawer = () => {
    console.log("toggle", open);
    setOpen(!open);
  };
  const location = useLocation();
  return (
    <div
      className={` ${
        location.pathname === "/horizontal" && " w-[100vh] lg:w-full"
      } `}
    >
      <header
        className={`flex  ${open?'z-[99999]':'z-50'} justify-between bg-transparent bg-opacity-90 fixed top-0 right-0 left-0 lg:py-2ççç py-3 lg:px-20 px-5  ${
          location.pathname === "/horizontal" && " w-[100vh] lg:w-auto"
        } `}
      >
        <div className="menu">
          <button
            className={`flex flex-col h-full justify-evenly `}
            onClick={toggleDrawer}
            //  onClick={()=>{!open&&openDrawer();console.log("ww")}}
          
          >
            <div className="hidden dark:block ">
              <Hamburger
                toggled={open}
                // toggle={toggleDrawer}
                size={20}
                color={!open ? "#FFFFFF" : "#FF8A00"}
                duration={2}
              />
            </div>
            <div className="block dark:hidden">
              <Hamburger
                toggled={open}
                // toggle={toggleDrawer}
                size={20}
                color={!open ? "#000000" : "#FF8A00"}
                duration={2}
              />
            </div>
            {/* <Hamburger toggled={open} toggle={setOpen}  size={20} color="#FF8A00" duration={2} /> */}
            {/* <span className="lg:w-7 w-5 h-[2px]  dark:bg-white bg-black  content-between "></span>
            <span className="lg:w-7 w-5 h-[2px]  dark:bg-white bg-black  content-between "></span>
            <span className="lg:w-7 w-5 h-[2px]  dark:bg-white bg-black  content-between "></span> */}
          </button>
        </div>
        <div className="logo">
          <a href="/" className="cursor-pointer">
            <img
              src={logo}
              alt=""
              className="hidden w-6 mt-2 bg-black lg:w-8 dark:bg-transparent dark:inline-block"
            />
            <img
              src={logoLight}
              alt=""
              className="inline-block w-6 mt-2 lg:w-8 dark:hidden"
            />
          </a>
        </div>
      </header>

      <Drawer
        open={open}
        // onClose={closeDrawer}
        size={1050}
        className="hidden lg:block w-full mt-[0] lg:mt-0 lg:w-[350px] md:w-[600px]  !max-w-full bg-white dark:bg-[#101010] py-24 px-14 lg:px-24 z-[10000] overflow-auto lg:overflow-visible"
      >
        <div className="flex items-center justify-between pb-4 mb-2 border-b border-b-mainColor">
          <h2 className="text-2xl text-mainColor font-Inria">
            <a href="/">House</a>
          </h2>
          {/* <button onClick={closeDrawer}>
            <Hamburger
              toggled={open}
              toggle={setOpen}
              size={20}
              color="#FF8A00"
              duration={2}
            />
         
          </button> */}
        </div>
        <ul className="mt-8">
          <li className="mb-4">
            <a
              href="/kitchen"
              className="text-black dark:text-white font-Inria"
            >
              Kitchen
            </a>
          </li>
          <li className="mb-4">
            <a
              href="/bedroom"
              className="text-black dark:text-white font-Inria"
            >
              Bedroom
            </a>
          </li>
          <li className="mb-4">
            <a
              href="/bathroom"
              className="text-black dark:text-white font-Inria"
            >
              Bathroom
            </a>
          </li>
          <li className="mb-4">
            <a
              href="/livingroom"
              className="text-black dark:text-white font-Inria"
            >
              Living room
            </a>
          </li>
          <li className="mb-4">
            <a href="/closet" className="text-black dark:text-white font-Inria">
              Closet
            </a>
          </li>
        </ul>

        <a
          href="/aboutus"
          className="block text-2xl text-black dark:text-white font-Inria mt-11 before:w-1 "
        >
          About us
        </a>
        <a
          href="/contactus"
          className="block text-2xl text-black dark:text-white font-Inria mt-11"
        >
          Contact us
        </a>

        <CustomSwitch
          className="mt-10"
          onChange={() => {
            onChange();
            setTimeout(() => {
              closeDrawer();
              // toggleDrawer();
            }, 250);
          }}
          darkToggle={darkToggle}
        />

        <div
          className="w-0 h-0 
  border-t-[50vh] border-t-transparent
  border-l-[80px] dark:border-l-[#101010] 
  border-l-[#fff] 
  border-b-[50vh] border-b-transparent
  absolute top-0 bottom-0 -right-20
  hidden lg:block
  "
        ></div>
      </Drawer>
      <Drawer
        open={open}

        size={1050}
        className="block lg:hidden w-full mt-[0] lg:mt-0 lg:w-[350px] md:w-[600px]  !max-w-full bg-white dark:bg-[#101010] py-24 px-14 lg:px-24 z-[10000] overflow-auto lg:overflow-visible"
      >
        <div className="flex items-center justify-between pb-4 mb-2 border-b border-b-mainColor">
          <h2 className="text-2xl text-mainColor font-Inria">
            <a href="/">House</a>
          </h2>
          {/* <button onClick={closeDrawer}>
            <Hamburger
              toggled={open}
              toggle={setOpen}
              size={20}
              color="#FF8A00"
              duration={2}
            />
         
          </button> */}
        </div>
        <ul className="mt-8">
          <li className="mb-4">
            <a
              href="/kitchen"
              className="text-black dark:text-white font-Inria"
            >
              Kitchen
            </a>
          </li>
          <li className="mb-4">
            <a
              href="/bedroom"
              className="text-black dark:text-white font-Inria"
            >
              Bedroom
            </a>
          </li>
          <li className="mb-4">
            <a
              href="/bathroom"
              className="text-black dark:text-white font-Inria"
            >
              Bathroom
            </a>
          </li>
          <li className="mb-4">
            <a
              href="/livingroom"
              className="text-black dark:text-white font-Inria"
            >
              Living room
            </a>
          </li>
          <li className="mb-4">
            <a href="/closet" className="text-black dark:text-white font-Inria">
              Closet
            </a>
          </li>
        </ul>

        <a
          href="/aboutus"
          className="block text-2xl text-black dark:text-white font-Inria mt-11 before:w-1 "
        >
          About us
        </a>
        <a
          href="/contactus"
          className="block text-2xl text-black dark:text-white font-Inria mt-11"
        >
          Contact us
        </a>

        <CustomSwitch
          className="mt-10"
          onChange={() => {
            onChange();
            setTimeout(() => {
              closeDrawer();
              // toggleDrawer();
            }, 250);
          }}
          darkToggle={darkToggle}
        />

        <div
          className="w-0 h-0 
  border-t-[50vh] border-t-transparent
  border-l-[80px] dark:border-l-[#101010] 
  border-l-[#fff] 
  border-b-[50vh] border-b-transparent
  absolute top-0 bottom-0 -right-20
  hidden lg:block
  "
        ></div>
      </Drawer>
    </div>

  );
};

export default HeaderMain;
