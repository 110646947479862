import { forwardRef } from "react";
import { IconSvgProps } from "./IconSvgProps";

const Telegram = forwardRef<SVGSVGElement, IconSvgProps>((props, ref) => {
    const { className, size = 24, color = "currentColor", strokeWidth = 1 } = props;

    return (
        <svg className={className}  width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.943 6.63866L21.9249 21.9134C21.6972 22.9915 21.1034 23.2598 20.2596 22.7519L15.6609 19.1153L13.442 21.4056C13.1964 21.6691 12.9911 21.8895 12.5178 21.8895L12.8482 16.8634L21.3713 8.59832C21.7418 8.24376 21.2909 8.04732 20.7953 8.40188L10.2587 15.5218L5.72255 13.9982C4.73585 13.6676 4.718 12.9393 5.92793 12.4314L23.6706 5.09586C24.4921 4.76525 25.2109 5.2923 24.943 6.63866Z" fill="white"/>
        </svg>
        
        
    );
});

Telegram.displayName = "Telegram";

export default Telegram;
