import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import LightIcon from "../assets/icon/light";
import NightIcon from "../assets/icon/night";

export default function MyToggle(props: any) {
  const [enabled, setEnabled] = useState(!props.darkToggle || false);

  return (
    <Switch.Group>
      <div className={`flex items-center ${props.className}`}>
        <Switch
          checked={enabled}
          onChange={() => {
            setEnabled(!enabled);
            props.onChange(!enabled);
          }}
          className={`${
            !enabled ? "bg-white" : "bg-[#0F0F0F]"
          } relative inline-flex h-11 w-28 items-center rounded-full transition-colors  border-mainColor border`}
        >
          <span
            className={`${
              enabled ? "translate-x-[60px]" : "translate-x-2 "
            }  h-9 w-9 transform   transition-transform flex justify-center items-center `}
          >
            {enabled ? (
              <span className="text-white">Night</span>
            ) : (
              <span>Day</span>
            )}
          </span>

          <span
            className={`${
              !enabled
                ? "translate-x-[37px] bg-black"
                : "-translate-x-[30px] bg-white"
            }  h-8 w-8 transform rounded-full  transition-transform flex justify-center items-center overflow-hidden`}
          >
            {!enabled ? <LightIcon /> : <NightIcon />}
          </span>
        </Switch>
      </div>
    </Switch.Group>
  );
}
