import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import HeaderMain from "../header";
import FooterMain from "../footer";
import { useLocation } from "react-router-dom";

interface LayoutProps {
  children: ReactNode;
}

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const [darkToggle, setDarkToggle] = useState(true);
 
  useEffect(() => {
    localStorage.getItem("darkMode") &&
    setDarkToggle(JSON.parse(localStorage.getItem("darkMode")||''));
    // console.log("ss", localStorage.getItem("darkMode"))
  }, []);
 
  useEffect(() => {
    // if (location.pathname === "/horizontal") document.body.classList.add("rotate-90");

    document.body.classList.add("lg:rotate-0");

    return () => {
      document.body.classList.remove("rotate-90");
    };
  }, []);
  return (
    // {
    //   orientation !==0 &&
    //   "w-[100vh] h-[100vw] lg:w-auto lg:h-screen"
    // }
    <>
      <div
        className={`${darkToggle && "dark"}  
     
        `}
      >
        {/* <div className={location.pathname==="/"?`rotate-90 lg:rotate-0 h-[100vw] w-[100]`:""}> */}
        <HeaderMain
       darkToggle={darkToggle}
          onChange={() => {
            // alert("sss")
            localStorage.setItem("darkMode", JSON.stringify(!darkToggle));
            setDarkToggle(!darkToggle);
          }}
        />
        {children}
        {/* <FooterMain /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default Layout;
