import { FunctionComponent } from "react";
import ArrowDown from "../../assets/icon/arrowDown";
import Jump from "react-reveal/Jump";
import Fade from "react-reveal/Fade";
// import motion from "../../assets/Kitchen_Motion.mp4";
import motion from "../../assets/Kitchen_isometric2.gif"
interface MotionSectionProps {
  handlePageChange: any;
  title: string;
}

const MotionSection: FunctionComponent<MotionSectionProps> = ({
  handlePageChange,
  title,
}) => {
  return (
    <div
      id="page1"
      className="relative flex justify-center h-screen mb-0 overflow-hidden bg-black lg:mb-12 "
    >
      <div className="absolute top-0 bottom-0 left-0 right-0 z-20 bg-white bg-opacity-40 dark:bg-black dark:bg-opacity-40"></div>
      <Fade top  duration={2000}>
        <div className="relative z-30 text-5xl text-black dark:text-white mt-[300px] font-Inria">
          {title}
        </div>
      </Fade> 
      <img src={motion} alt=""  className="absolute z-10 h-[50%] mt-[40%] lg:mt-0 max-w-fit lg:max-w-full lg:h-full lg:w-screen" onLoad={()=>{setTimeout(()=>{ handlePageChange(1)},10000)}} />
      {/* <video
        autoPlay
        onPlay={() => {
          // alert("play")
        }}
        onEnded={() => {
          handlePageChange(1);
        }}
        muted
        className="absolute z-10 h-screen max-w-fit lg:max-w-full lg:h-full lg:w-screen"
      >
        <source src={motion} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <div className="absolute z-30 bg-black bg-opacity-50 -bottom-[150px] w-[250px] h-[250px] rounded-full  text-center lg:block hidden ">
        <button
          className="mt-5 text-sm text-white cursor-pointer"
          onClick={() => {
            handlePageChange(1);
          }}
        >
          <Jump
            forever={true}
            delay={1000}
            count={5}
            duration={3000}
            wait={20000}
          >
            <ArrowDown />
          </Jump>
          <span className="inline-block mt-2">Cabinet</span>
        </button>
      </div>
    </div>
  );
};

export default MotionSection;
