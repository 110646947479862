
import { forwardRef } from "react";
import { IconSvgProps } from "./IconSvgProps";

const Whatsapp = forwardRef<SVGSVGElement, IconSvgProps>((props, ref) => {
    const { className, size = 24, color = "currentColor", strokeWidth = 1 } = props;

    return (
        <svg width="30" className={className}  height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.0045 7.90625C20.1339 6.03125 17.6429 5 14.9955 5C9.53125 5 5.08482 9.44643 5.08482 14.9107C5.08482 16.6562 5.54018 18.3616 6.40625 19.8661L5 25L10.2545 23.6205C11.7009 24.4107 13.3304 24.8259 14.9911 24.8259H14.9955C20.4554 24.8259 25 20.3795 25 14.9152C25 12.2679 23.875 9.78125 22.0045 7.90625ZM14.9955 23.1563C13.5134 23.1563 12.0625 22.7589 10.7991 22.0089L10.5 21.8304L7.38393 22.6473L8.21429 19.6071L8.01786 19.2946C7.19196 17.9821 6.75893 16.4688 6.75893 14.9107C6.75893 10.3705 10.4554 6.67411 15 6.67411C17.2009 6.67411 19.2679 7.53125 20.8214 9.08929C22.375 10.6473 23.3304 12.7143 23.3259 14.9152C23.3259 19.4598 19.5357 23.1563 14.9955 23.1563ZM19.5134 16.9866C19.2679 16.8616 18.0491 16.2634 17.8214 16.183C17.5938 16.0982 17.4286 16.058 17.2634 16.308C17.0982 16.558 16.625 17.1116 16.4777 17.2813C16.3348 17.4464 16.1875 17.4688 15.942 17.3438C14.4866 16.6161 13.5312 16.0446 12.5714 14.3973C12.317 13.9598 12.8259 13.9911 13.2991 13.0446C13.3795 12.8795 13.3393 12.7366 13.2768 12.6116C13.2143 12.4866 12.7188 11.2679 12.5134 10.7723C12.3125 10.2902 12.1071 10.3571 11.9554 10.3482C11.8125 10.3393 11.6473 10.3393 11.4821 10.3393C11.317 10.3393 11.0491 10.4018 10.8214 10.6473C10.5938 10.8973 9.95536 11.4955 9.95536 12.7143C9.95536 13.933 10.8438 15.1116 10.9643 15.2768C11.0893 15.442 12.7098 17.942 15.1964 19.0179C16.7679 19.6964 17.3839 19.7545 18.1696 19.6384C18.6473 19.567 19.6339 19.0402 19.8393 18.4598C20.0446 17.8795 20.0446 17.3839 19.9821 17.2813C19.9241 17.1696 19.7589 17.1071 19.5134 16.9866Z" fill="white"/>
        </svg>
        
        
        
    );
});

Whatsapp.displayName = "Whatsapp";

export default Whatsapp;
