import { Children, FunctionComponent, ReactNode, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import image from "../../assets/image/image 10.jpg";
import image2 from "../../assets/image/image 11.jpg";
import ArrowRight from "../../assets/icon/arrowRight";
import Marquee from "react-fast-marquee";
import gif from "../../assets/animateAbout.gif";
import FooterMain from "../../components/footer";

interface AboutUsProps {
  children?: ReactNode;
}

const AboutUs: FunctionComponent<AboutUsProps> = (props) => {
  const swiperInit = {
    modules: [Navigation],
    spaceBetween: 30,
    slidesPerView: 3.3,
    // onSlideChange:onSlideChange,
    loop: true,
    allowTouchMove: false,
    navigation: {
      prevEl: "#prevRef",
      nextEl: "#nextRef",
    },
    breakpoints: {
      "@0.00": {
        slidesPerView: 1.4,
        spaceBetween: 20,
      },
      "@0.75": {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      "@1.00": {
        slidesPerView: 2.7,
        spaceBetween: 20,
      },
      "@1.50": {
        slidesPerView: 3.3,
        spaceBetween: 30,
      },
    },
  };
  return (
    <>
      <section className="relative flex flex-col justify-start w-screen h-screen bg-no-repeat bg-cover lg:pt-20">
        <div>
          <div className="absolute top-0 bottom-0 left-0 right-0 z-40 ">
            <div className="absolute top-0 bottom-0 left-0 right-0 z-[5] bg-white dark:bg-black !bg-opacity-70 "></div>
            <img src={gif} alt="" className="w-screen h-screen " />
          </div>
          <section className="relative text-center z-40 flex flex-col  ">
            <div className="flex flex-col  justify-center w-full px-5 pt-20 pb-5 text-left lg:py-0 lg:w-full lg:px-36">
              <h3 className="mb-6 text-3xl text-center text-white lg:text-5xl font-Inria before:w-7 before:h-[3px] relative before:absolute before:-bottom-2 before:bg-white">
                About us
              </h3>
              <p className="leading-relaxed text-center text-white">
               We are glad to welcome you to Square Studio Homes, where we specialize in modern
interior designing solutions. Square Studio Homes Inc. has accumulated over two decades
of experience in visionary interior design. Originating in Iran and extending into Canada in
2012, our path mirrors a combination of global expertise and a profound understanding of
the local market. This collaboration empowers us to create distinct spaces that reflect both
international trends and a thorough understanding of regional tastes.
<br></br><br></br>

Our mission is to transform your spaces and create areas that inspire. Balancing innovative
home solutions with modern functionality is at the core of our design philosophy. Our team of
creative and experienced designers can transform any space, be it big or small, residential
or commercial, into your own paradise. Our primary focus is on modern designs. We specialize in creating contemporary spaces
that are not only aesthetically pleasing, but also functional. Our focus on blending modern
aesthetics with innovative technology is a significant part of our identity and it is what sets us
apart in the industry.
              </p>
            </div>
            <br></br><br></br><br></br><br></br><br></br>
            <div className="relative flex-col max-w-full	 h-[200px] lg:h-55 items-center justify-center w-full lg:pl-10 lg:full ">
              <Swiper {...swiperInit} className="h-full">
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="h-full mx-auto lg:h-full lg:w-auto ">
                    <img
                      src={image2}
                      alt=""
                      className="w-full aspect-auto-[3/4]"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
              <div
                className="cursor-pointer  lg:flex swiper-button-next_aboutUs rotate-180 lg:rotate-0 z-[10000]"
                id="nextRef"
              >
                <ArrowRight className="inline ml-2 rotate-0 lg:rotate-180" />
              </div>

              <div
                className="cursor-pointer   flex right-0 left-auto swiper-button-next_aboutUs z-[10000]"
                id="prevRef"
              >
                <ArrowRight className="inline ml-2 " />
              </div>
            </div>
          </section>
        </div>
        <section className="z-50 mt-10 mb-5">
          <Marquee>
            <p className="z-30 text-2xl text-white lg:text-8xl font-Inconsolata overflow-hidden	">
              Square design Studio . Build . Interior design Studio . Construction .{" "}
            </p>
          </Marquee>
        </section>
        {props.children}
      </section>
    </>
  );
};

export default AboutUs;
