import { Children, FunctionComponent, ReactNode } from "react";
import image from "../../assets/image/aboutusimage.png";
import award from "../../assets/image/awards.png";
import FooterMain from "../../components/footer";
interface AwardsProps {
  children?: ReactNode;
}

const Awards: FunctionComponent<AwardsProps> = ({ children }) => {
  return (
    <>
      <div
        className="w-screen h-full text-center bg-black bg-contain "
        style={{ backgroundImage: `url${image}` }}
      >
        <div className="absolute top-0 bottom-0 left-0 right-0 z-20 bg-black "></div>

        <div className="relative h-full">
          <h2 className="pt-32 text-2xl text-white lg:text-5xl lg:pt-0 lg:mt-32 font-Inria">
            Our awards
          </h2>
          <p className="mt-3 text-base text-white font-inter lg:w-[700px] w-full px-5 lg:px-0 lg:mx-auto">
           We are proud to say that within the design industry, our attention to detail and commitment to
excellence has garnered recognition and acclaim. We are pleased to showcase the
prestigious awards that reflect our dedication to quality, innovation, and client satisfaction.

          </p>
          <ul className="flex flex-col lg:flex-row gap-5 mx-auto lg:w-[700px] mt-10 w-full relative z-50">
            <li>
              <img src={award} className="mx-auto" alt="" />
            </li>
            <li>
              <img src={award} className="mx-auto" alt="" />
            </li>
            <li>
              <img src={award} className="mx-auto" alt="" />
            </li>
          </ul>

          <a
            href="/contactus"
            className="relative z-50 inline-block px-10 py-4 mt-16 text-3xl bg-gray-600"
          >
            Contact Us <span className="text-mainColor">NOW</span>
          </a>
          {children}
        </div>
      </div>
    </>
  );
};

export default Awards;
