import { forwardRef } from "react";
import { IconSvgProps } from "./IconSvgProps";

const LightIcon = forwardRef<SVGSVGElement, IconSvgProps>((props, ref) => {
  const {
    className,
    size = 15,
    color = "#fff",
    strokeWidth = 1,
  } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <circle cx="20" cy="20" r="20" fill="black"/>
    <path d="M20 8V32" stroke="white" strokeWidth="0.761905" strokeLinecap="round"/>
    <path d="M28.3994 11.4287L11.4289 28.3993" stroke="white" strokeWidth="0.761905" strokeLinecap="round"/>
    <path d="M28.3994 28.5713L11.4289 11.6007" stroke="white" strokeWidth="0.761905" strokeLinecap="round"/>
    <path d="M32 20L8 20" stroke="white" strokeWidth="0.761905" strokeLinecap="round"/>
    <circle cx="19.9997" cy="19.9997" r="6.85714" fill="white"/>
    <circle cx="20.0001" cy="20.0001" r="7.57143" stroke="black" strokeWidth="2"/>
    </svg>
  );
});

LightIcon.displayName = "LightIcon";

export default LightIcon;
