import { FunctionComponent, useEffect, useState } from "react";
import Jump from "react-reveal/Jump";
import Arrow from "../../assets/icon/arrow";
import Render3D from "./render3D.js";
import logo from "../../assets/logo/logo.svg";
import logolight from "../../assets/logo/logolight.svg";
interface HomePageVerticalProps {}

const HomePageVertical: FunctionComponent<HomePageVerticalProps> = () => {
  const [scrollEnable, setscrollEnable] = useState(false);
  const [scrollEnable1, setscrollEnable1] = useState(false);
  const [hiddenEl, sethiddenEl] = useState(false);
  const [Homeloading, setHomeloading] = useState(false);
  const [orientation, setOrientation] = useState<number>(window.orientation);

  const handleOrientationChange = () => {
    setOrientation(window.orientation);
  };

  useEffect(() => {
    // Add event listener for orientation change
    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      // Clean up the event listener when component is unmounted
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);
  useEffect(() => {
    if (sessionStorage.getItem("setscrollEnable") === "false") {
      setscrollEnable(true);
      sethiddenEl(true);
      setHomeloading(true);
    }

    setTimeout(() => {
      setHomeloading(true);
    }, 3000);
  }, []);
  return (
    <div
      className="relative lg:w-screen lg:h-screen lg:overflow-hidden"
      onWheel={() => {
        if (!scrollEnable) {
          // console.log("ss", scrollEnable);
        }
        // setscrollEnable(true);
        sessionStorage.setItem("setscrollEnable", "false");
        // console.log("ss", scrollEnable);
      setscrollEnable1(true);

        setTimeout(() => {
          setscrollEnable(true);

          sethiddenEl(true);
          setHomeloading(true);
        }, 3000);
      }}
      onClick={() => {
        if (!scrollEnable) {
          // console.log("ss", scrollEnable);
        }
        // setscrollEnable(true);
        sessionStorage.setItem("setscrollEnable", "false");
      //  alert("ss");
      setscrollEnable1(true);
        setTimeout(() => {
          setscrollEnable(true);

          sethiddenEl(true);
          setHomeloading(true);
        }, 3000);
      }}
      onTouchStart={() => {
        if (!scrollEnable) {
          // console.log("ss", scrollEnable);
        }

        sessionStorage.setItem("setscrollEnable", "false");
        // alert("ss");
        setscrollEnable1(true);

        setTimeout(() => {
          setscrollEnable(true);
          
          // console.log("ss", scrollEnable);
          sethiddenEl(true);
          setHomeloading(true);
        }, 3000);
      }}
    >
      <section
        className={`absolute items-center justify-center w-full h-[100vh] bg-center lg:bg-top bg-no-repeat bg-cover ${
          orientation !== 0
            ? "lg:h-screen lg:bg-cover content-stretch overflow-hidden lg:dark:bg-HomeBg-dark lg:bg-HomeBg-light dark:bg-HomeBg-dark bg-HomeBg-light "
            : "lg:h-screen lg:bg-cover content-stretch overflow-hidden lg:dark:bg-HomeBg-dark lg:bg-HomeBg-light dark:bg-HomeWBg-dark bg-HomeVBg-light "
        }`}
        // style={{ backgroundImage: `url(${darkBgImage})` }}
      >
        <div className="absolute inset-0 z-10 flex justify-center h-[100vh] w-[100vw] lg:w-auto lg:h-screen overflow-hidden">
          {/* <img src={darkBgImage} alt=""   className="h-screen lg:max-w-full lg:h-auto max-w-none lg:w-full"/> */}
        </div>
        <div className="absolute inset-0 z-20 dark:bg-black dark:bg-opacity-40 bg-opacity-40 bg-white h-[100vh] w-[100vw] lg:w-auto lg:h-auto"></div>
        <div
          className={`absolute ${
            orientation !== 0 ? " -bottom-[0px] " : " -bottom-[50px] "
          } lg:bottom-[30px] lg:relative z-30 flex justify-center items-end w-[100vw] lg:w-screen mx-auto mt-[200px] lg:mt-[50px] h-[70%]  mb-10 lg:h-full image`}
        >
          <Render3D orientation={orientation} />
          {/* <Render3D  /> */}
        </div>
      </section>
      <div
        className={`absolute w-screen h-[100vh] lg:h-screen flex-col z-50 top-0 bottom-0 left-0 right-0 flex items-center justify-center  dark:bg-black bg-white dark:bg-opacity-100 bg-opacity-100  ${
          scrollEnable && "animate-ping"
        }
        ${hiddenEl && "hidden"}
        bg-cover ${
          orientation !== 0
            ? "lg:h-screen lg:bg-cover content-stretch overflow-hidden lg:dark:bg-HomeBg-dark lg:bg-HomeBg-light dark:bg-HomeBg-dark bg-HomeBg-light "
            : "lg:h-screen lg:bg-cover content-stretch overflow-hidden lg:dark:bg-HomeBg-dark lg:bg-HomeBg-light dark:bg-HomeWBg-dark bg-HomeVBg-light "
        }
        `}
      >
        <div className="absolute inset-0  dark:bg-gray-700 z-50  bg-white h-[100vh] w-[100vw] lg:w-auto lg:h-auto"></div>

        <h1 className="z-50 hidden mb-10 text-4xl text-black lg:text-9xl dark:text-white font-Inria dark:block ">
          {/* SQUARE */}
          <img
            src={logo}
            alt=""
            className="bg-black w-36 lg:w-40 dark:bg-transparent"
          />
        </h1>
        <h1 className="z-50 block mb-10 text-4xl text-black lg:text-9xl dark:text-white font-Inria dark:hidden ">
          {/* SQUARE */}
          <img
            src={logolight}
            alt=""
            className="block w-36 lg:w-48 dark:bg-transparent"
          />
        </h1>

        {/* <Jump forever={true} delay={2000} count={0} duration={8000} wait={200}> */}
        <div className="z-50 p-1 ">
          {/* <div className="px-5 py-4 border border-black dark:border-white "> */}
          <div className="flex flex-col items-center justify-center ">
            {/* <div className="centerBox">
                <div className="categoryWrapper">
                  <h1 className="!text-3xl font-Inria">Scroll</h1>
                
                  <button>
                    <span>
                      <span>
                        <span className="!text-base !leading-[2rem]" data-attr-span="  to show.">
                          to show.
                        </span>
                      </span>
                    </span>
                  </button>
                </div>
              </div> */}
            <div className={`mt-32 flex  justify-center svg-wrapper `}>
              <svg
                height="120"
                width="120"
                xmlns="http://www.w3.org/2000/svg"
                className="-rotate-90"
              >
                <rect
                  className={`shape   ${scrollEnable1 && "active"}`}
                  height="120"
                  width="120"
                />
              </svg>
            </div>
            <div className="mx-auto -mt-20 text-center text">Hold</div>

            {/* <Arrow
                className="p-2 -rotate-90 bg-black rounded-full dark:bg-transparent"
                size={25}
                color="#fff"
              /> */}
          </div>
        </div>
        {/* </div> */}
        {/* </Jump> */}
      </div>
    </div>
  );
};

export default HomePageVertical;
