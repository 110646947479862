import { forwardRef } from "react";
import { IconSvgProps } from "./IconSvgProps";

const Arrow = forwardRef<SVGSVGElement, IconSvgProps>((props, ref) => {
  const {
    className,
    size = 15,
    color = "#fff",
    strokeWidth = 1,
  } = props;

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 13 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22.5L1.5 12L12 1.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

Arrow.displayName = "Arrow";

export default Arrow;
