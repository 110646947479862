
import { forwardRef } from "react";
import { IconSvgProps } from './IconSvgProps';

const Facebooklight = forwardRef<SVGSVGElement, IconSvgProps>((props, ref) => {
    const { className, size = 24, color = "currentColor", strokeWidth = 1 } = props;

    return (
        <svg  className={className}  width="30" height="30" viewBox="0 0 30 30" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path d="M19.0656 15.007H16.6127C16.6127 18.926 16.6127 23.75 16.6127 23.75H12.9778C12.9778 23.75 12.9778 18.9728 12.9778 15.007H11.25V11.9169H12.9778V9.91823C12.9778 8.48677 13.658 6.25 16.6461 6.25L19.3395 6.26032V9.25989C19.3395 9.25989 17.7028 9.25989 17.3846 9.25989C17.0663 9.25989 16.6139 9.41901 16.6139 10.1016V11.9175H19.3833L19.0656 15.007Z" fill="#050505"/>
        <mask id="mask0_2335_1495" maskUnits="userSpaceOnUse" x="11" y="6" width="9" height="18">
        <path d="M19.0656 15.007H16.6127C16.6127 18.926 16.6127 23.75 16.6127 23.75H12.9778C12.9778 23.75 12.9778 18.9728 12.9778 15.007H11.25V11.9169H12.9778V9.91823C12.9778 8.48677 13.658 6.25 16.6461 6.25L19.3395 6.26032V9.25989C19.3395 9.25989 17.7028 9.25989 17.3846 9.25989C17.0663 9.25989 16.6139 9.41901 16.6139 10.1016V11.9175H19.3833L19.0656 15.007Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_2335_1495)">
        <rect width="30" height="30" fill="#050505"/>
        </g>
        </svg>
        
        
    );
});

Facebooklight.displayName = "Facebooklight";

export default Facebooklight;
