import { forwardRef } from "react";
import { IconSvgProps } from "./IconSvgProps";

const ArrowLeftSlider = forwardRef<SVGSVGElement, IconSvgProps>((props, ref) => {
    const { className, size = 24, color = "currentColor", strokeWidth = 1 ,onClick} = props;

    return (
        <svg width="74" className={className} height="42" viewBox="0 0 74 42" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <path d="M25 21L65 21"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M53 33.25L65.25 21L53 8.75"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    );
});

ArrowLeftSlider.displayName = "ArrowLeftSlider";

export default ArrowLeftSlider;
