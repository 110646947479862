import { FunctionComponent } from "react";
import image from "../../assets/image/aboutusimage.png";

interface WhyUsProps {}

const WhyUs: FunctionComponent<WhyUsProps> = () => {
  return (
    <div className="w-screen bg-black lg:h-screen">
    <div className="lg:w-[746px] w-full m-auto pt-32 px-5 lg:px-0"><br /><br /><br />
      <h2 className="mb-6 text-3xl text-center text-white lg:text-5xl font-Inria before:w-7 before:h-[3px] relative before:absolute before:-bottom-2 before:bg-white">Why us</h2><br />

      <ul className="mb-12 text-sm text-center text-white lg:text-base font-inter">
      <li>Our team of skilled designers is committed to delivering smart solutions that elevate your
space. With a focus on customized designs tailored to your individualistic approach, we bring
your vision to life. 


<br /><br />

<li><b>Personalized Attention</b></li>
We pride ourselves on offering personalized attention to each client. We work closely
with all our clients to ensure complete satisfaction with the final result. Your project is
not just another job for us; it is an opportunity to create something extraordinary that
brings joy into people's homes and lives.

<br></br><br></br>
<li><b>Transparency and Communication</b></li>
We value open communication and transparency throughout the design process. You
will be involved and informed every step of the way, ensuring that the final result
meets and exceeds your expectations.</li>
</ul>
   <ul className="flex flex-col justify-center text-center lg:mb-10 lg:justify-between lg:flex-row">
    <li className="mb-7 lg:mb-0">
        <p className="mb-3 text-4xl lg:text-6xl text-mainColor">+12</p>
        <p className="text-base text-white lg:text-xl">Years experience</p>
    </li>
    <li className="mb-7 lg:mb-0">
        <p className="mb-3 text-4xl lg:text-6xl text-mainColor">+10</p>
        <p className="text-base text-white lg:text-xl">Successful Project</p>
    </li>
    <li className="mb-7 lg:mb-0">
        <p className="mb-3 text-4xl lg:text-6xl text-mainColor">+8</p>
        <p className="text-base text-white lg:text-xl">City supporting</p>
    </li>
   </ul>
    </div>
   <img src={image} alt=""  className="h-screen max-w-fit lg:max-w-full lg:w-full lg:h-auto"/>
    </div>
  );
};

export default WhyUs;
