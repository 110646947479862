import { forwardRef } from "react";
import { IconSvgProps } from "./IconSvgProps";

const ArrowDown = forwardRef<SVGSVGElement, IconSvgProps>((props, ref) => {
    const { className, size = 24, color = "currentColor", strokeWidth = 1 } = props;

    return (
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="23" cy="23" r="22.5" fill="white" fill-opacity="0.05" stroke="white"/>
        <path d="M23 16V30" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M30 23L23 30L16 23" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    );
});

ArrowDown.displayName = "ArrowDown";

export default ArrowDown;
